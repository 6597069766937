.wrapper {
  background-color: #f9f9f9;
  padding: 10px 70px;
}
.container-wrapper {
  padding: 0;
}
.candidate-list-container {
  margin-top: 35px;
}
.syllabus-subtext {
  color: #8a8a8a;
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 15px;
}
.centre-head {
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 20px;
}
.download-SAG {
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #037fac;
  font-weight: 700;
}
.submit-all {
  padding: 10px 15px;
  margin: 10px;
  width: 125px;
  border-radius: 10px;
  border: 0;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  background-color: #018503;
}
.upload-AR {
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  background-color: #037fac;
}
.position {
  position: absolute;
}

.marginFix {
  margin-left: 170px;
}

.submit-all:disabled {
  cursor: not-allowed !important;
  background-color: #239c48;
}

.upload-AR:disabled {
  cursor: not-allowed !important;
}

.student-instruction {
  font-size: 16px;
}
.igcse-label {
  margin-right: 10px;
  font-weight: 600;
  font-size: 20px;
}
.candidate-number {
  margin-left: 10px;
  font-weight: 600;
  font-size: 20px;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600;
}

.file-count {
  font-weight: 600;
  font-size: 18px;
  color: gray;
}
.infoText {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.btn-status-text {
  font-size: 14px;
  padding: 0;
  font-weight: 700 !important;
}

.modal-dialog-auto {
  width: 680px !important;
  max-width: 1920px !important;
  border-radius: 0.5rem !important;
}
.cancelModal {
  cursor: pointer;
}
.customCheckbox {
  width: 1rem;
  height: 1.25rem;
  margin-top: 1rem !important;
}
.modalBtn {
  background-color: lightgray !important;
  border: none;
  color: gray !important;
}
.prev-candidate {
  width: 180px !important;
  padding: 6px 20px !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  display: inline-block !important;
  font-weight: 400 !important;
  border: 2px solid #037fac !important;
  border-radius: 0.5rem !important;
  text-align: center;
  vertical-align: middle;
  color: #f9f9f9 !important;
  background-color: #037fac !important;
}

.btn-secondary {
  border-color: lightgray !important;
}

.evidenceLabel {
  font-weight: 600;
}

.dropdown-toggle::after {
  color: #037fac;
}

.card {
  border-radius: 0px !important;
}

.borderTop {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.borderBottom {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.dropdwnbtn {
  color: #0c0c0c !important;
  background-color: #f9f9f9 !important;
}

.evidence-dropdown {
  width: 175px;
  border-color: lightgray !important;
  border-radius: 9px;
  padding: 7px 18px 7px 5px;
  margin-right: 20px;
  color: gray;
  font-weight: 500;
  background-color: #f9f9f9;
}

.evidence-dropdown:focus {
  outline-color: gray;
}
.mr-10 {
  margin-right: 10px;
}
.mb-35 {
  margin-bottom: 35px;
}

.text-capitalize {
  text-transform: capitalize;
}

.sampleSelectionTab {
  font-size: 18px;
  font-weight: 700;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0px -3px 0px #037fac;
  border-radius: 0px;
  color: #037fac !important;
}

.navSection a {
  color: #000000;
}
