.custom-file-upload{
    background-color: #037FAC; 
    color: #fff; 
    border-radius: 0.5rem;
    cursor: pointer;
}
.grayText{
    color: gray;
  }


.custom-file-upload-disabled {
    cursor: not-allowed !important;
}