body {
  font-family: "Source Sans Pro" !important;
}

.align-centre {
  text-align: center;
}
.align-right {
  text-align: right;
}
.theme-label {
  color: #037fac;
}
.border-theme {
  border-color: #037fac !important;
}
.bold-text {
  font-weight: bold;
  color: #000;
  margin: 0;
}
.navigation-option {
  color: #808080;
  cursor: pointer;
}

.navigation-option:hover {
  color: #808080;
  text-decoration: underline;
  cursor: pointer;
}
.navigation-option span {
  text-transform: capitalize;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.elem-to-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.font-size18 {
  font-size: 18px;
}

.font-size20 {
  font-size: 20px;
}

.cursor-pointer {
  cursor: pointer;
}
